import React from 'react';
import { Scene } from '../../../types';
import './FinalScene.scss';

interface FinalSceneProps {
  scene: Scene;
  score: number;
  onRestart: () => void;
  onBackToList: () => void;
}

const FinalScene: React.FC<FinalSceneProps> = ({
  scene,
  score,
  onRestart,
  onBackToList
}) => {
  return (
    <div className="final-scene">
      {scene.images && scene.images.length > 0 && (
        <div 
          className="final-scene-background"
          style={{ backgroundImage: `url(${scene.images[0]})` }}
        />
      )}
      
      <div className="final-scene-content">
        <div className="final-scene-text">
          <h2 className={`final-scene-title ${scene.is_win ? 'win' : 'lose'}`}>
            {scene.is_win ? 'Победа!' : 'Игра окончена'}
          </h2>
          
          <div className="score-display">
            <span className="score-label">Ваш счет:</span>
            <span className="score-value">{score}</span>
          </div>
          
          <div className="final-scene-message">
            {scene.content.split('\n').map((paragraph: string, index: number) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        </div>
        
        <div className="final-scene-actions">
          <button 
            className="action-button restart"
            onClick={onRestart}
          >
            Начать заново
          </button>
          <button 
            className="action-button back"
            onClick={onBackToList}
          >
            К списку фильмов
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinalScene;