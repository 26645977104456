import React from 'react';
import { Movie, ApiVersion, Language } from '../../../types';
import './MovieList.scss';

interface MovieListProps {
  movies: Movie[];
  apiVersion: ApiVersion;
  language: Language;
  onMovieClick: (movie: Movie) => void;
  onApiVersionChange: (version: ApiVersion) => void;
  onLanguageChange: (language: Language) => void;
}

const MovieList: React.FC<MovieListProps> = ({ 
  movies, 
  apiVersion, 
  language,
  onMovieClick, 
  onApiVersionChange,
  onLanguageChange 
}) => {
  return (
    <div className="movie-list-container">
      <div className="version-selector">
        <div className="button-group">
          <button
            onClick={() => onApiVersionChange('v4')}
            className={`version-button ${apiVersion === 'v4' ? 'active' : ''}`}
          >
            API v4
            <small>(Мультиязычный)</small>
          </button>
          <button
            onClick={() => onApiVersionChange('v5')}
            className={`version-button ${apiVersion === 'v5' ? 'active' : ''}`}
          >
            API v5
            <small>(Мультистарт)</small>
          </button>
        </div>
        
        {['v4', 'v5'].includes(apiVersion) && (
          <div className="language-selector">
            <button
              onClick={() => onLanguageChange('ua')}
              className={`language-button ${language === 'ua' ? 'active' : ''}`}
            >
              Українська
            </button>
            <button
              onClick={() => onLanguageChange('en')}
              className={`language-button ${language === 'en' ? 'active' : ''}`}
            >
              English
            </button>
            <button
              onClick={() => onLanguageChange('ru')}
              className={`language-button ${language === 'ru' ? 'active' : ''}`}
            >
              Русский
            </button>
          </div>
        )}
      </div>
      
      <div className="movie-list">
        {movies.map(movie => (
          <button 
            key={movie.id} 
            onClick={() => onMovieClick(movie)}
            className={`movie-button ${movie.multilingual ? 'multilingual' : ''}`}
          >
            {movie.title}
            {movie.multilingual && (
              <span className="multilingual-badge">Мультиязычный</span>
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MovieList;