import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import MovieList from '../../components/Quest/MovieList/MovieList';
import SceneView from '../../components/Quest/SceneView/SceneView';
import LoadingSpinner from '../../components/Quest/LoadingSpinner/LoadingSpinner';
import { Scene, Movie, ApiVersion, Language, ISceneChoice } from '../../types';
import './styles/QuestScreen.scss';

const QuestScreen: React.FC = () => {
    const { movieId } = useParams<{ movieId: string }>();
    const location = useLocation();
    const navigate = useNavigate();
    const isListView = location.pathname === '/quest/list';
    
    const [movies, setMovies] = useState<Movie[]>([]);
    const [scene, setScene] = useState<Scene | null>(null);
    const [score, setScore] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [apiVersion, setApiVersion] = useState<ApiVersion>('v4');
    const [language, setLanguage] = useState<Language>('ua');
    const [fadeOut, setFadeOut] = useState(false);

    const BASE_API_URL = `https://movie-quest.et-rc.mggs.megogo.net/api/${apiVersion}`;
    const SCENES_API_URL = `${BASE_API_URL}${['v4', 'v5'].includes(apiVersion) ? `/${language}` : ''}`;

    const loadMovies = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_API_URL}/movies`);
            const data = await response.json();
            setMovies(data.movies);
        } catch (error) {
            console.error('Failed to load movies:', error);
        } finally {
            setIsLoading(false);
        }
    }, [BASE_API_URL]);

    const loadScene = useCallback(async (movieId: string, sceneId: string) => {
        setIsLoading(true);
        try {
            const response = await fetch(
                `${SCENES_API_URL}/movies/${movieId}/scenes/${sceneId}`
            );
            const data: Scene = await response.json();
            setScene(data);
        } catch (error) {
            console.error('Failed to load scene:', error);
        } finally {
            setIsLoading(false);
        }
    }, [SCENES_API_URL]);

    useEffect(() => {
        if (isListView || !movieId) {
            loadMovies();
        }
    }, [apiVersion, loadMovies, isListView, movieId]);

    useEffect(() => {
        if (movieId && movies.length > 0) {
            const movie = movies.find(m => m.id.toString() === movieId);
            if (movie) {
                loadScene(movieId, movie.start_scene_id.toString());
            }
        }
    }, [movieId, movies, loadScene]);

    const handleChoiceClick = async (choice: ISceneChoice) => {
        if (!scene || !movieId) return;
        
        setFadeOut(true);
        await new Promise(resolve => setTimeout(resolve, 300));
        if (choice.index === null) {
            setIsLoading(true);
            try {
                const response = await fetch(
                    `${SCENES_API_URL}/movies/${movieId}/scenes/${scene.id}/choices/${choice.id}`
                );
                const newScene: Scene = await response.json();
                setScene(newScene);
                setScore(score + 1);
            } catch (error) {
                console.error('Failed to generate new scene:', error);
            } finally {
                setIsLoading(false);
            }
        } else {
            loadScene(movieId, choice.index);
            setScore(score + 1);
        }

        setFadeOut(false);
        document.getElementById('content')?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleMovieClick = (movie: Movie) => {
        setScene(null);
        setScore(0);
        navigate(`/quest/movie/${movie.id}`);
    };

    const handleApiVersionChange = (version: ApiVersion) => {
        setApiVersion(version);
        setMovies([]);
        setScene(null);
    };

    const handleLanguageChange = (lang: Language) => {
        if (['v4', 'v5'].includes(apiVersion)) {
            setLanguage(lang);
            if (movieId) {
                const movie = movies.find(m => m.id.toString() === movieId);
                if (movie) {
                    loadScene(movieId, movie.start_scene_id.toString());
                }
            }
        }
    };

    const handleRestart = useCallback(() => {
        if (movieId) {
            const movie = movies.find(m => m.id.toString() === movieId);
            if (movie) {
                setScore(0);
                loadScene(movieId, movie.start_scene_id.toString());
            }
        }
    }, [movieId, movies, loadScene]);

    const handleBackToList = useCallback(() => {
        setScene(null);
        setScore(0);
        navigate('/quest/list');
    }, [navigate]);

    if (!movies.length && isLoading) {
        return <LoadingSpinner fullScreen size="large" />;
    }

    return (
        <div className="quest-screen">
            {(isListView || !movieId) && (
                <MovieList
                    movies={movies}
                    apiVersion={apiVersion}
                    language={language}
                    onMovieClick={handleMovieClick}
                    onApiVersionChange={handleApiVersionChange}
                    onLanguageChange={handleLanguageChange}
                />
            )}
            
            {(!isListView && movieId && scene) && (
                <SceneView
                    scene={scene}
                    score={score}
                    isLoading={isLoading}
                    fadeOut={fadeOut}
                    apiVersion={apiVersion}
                    onChoiceClick={handleChoiceClick}
                    onRestart={handleRestart}
                    onBackToList={handleBackToList}
                />
            )}
        </div>
    );
};

export default QuestScreen;
