import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./AppModeSelect.module.scss";

interface IPropTypes {}

const AppModeSelect = React.memo(
  React.forwardRef<HTMLDivElement, IPropTypes>((_, ref) => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;

    const handleModeSelect = (mode: string) => {
      switch (mode) {
        case 'quest':
          navigate('/quest');
          break;
        case 'quiz':
          navigate('/');
          break;
        case 'test':
          navigate('/test');
          break;
        default:
          break;
      }
    };

    const isSelected = (mode: string): boolean => {
      if (mode === 'quest') {
        return currentPath.startsWith('/quest');
      }
      if (mode === 'quiz') {
        return currentPath === '/' || currentPath.startsWith('/quiz');
      }
      if (mode === 'test') {
        return currentPath.startsWith('/test');
      }
      return false;
    };

    return (
      <div
        className={styles.AppModeSelect}
        ref={ref}
        data-role="app-mode-select"
      >
        <div
          className={cn(styles.AppModeSelect__Item, {
            [styles.AppModeSelect__Item_State_Selected]: isSelected('quiz'),
          })}
          onClick={() => handleModeSelect('quiz')}
        >
          quiz
        </div>
        <div
          className={cn(styles.AppModeSelect__Item, {
            [styles.AppModeSelect__Item_State_Selected]: isSelected('test'),
          })}
          onClick={() => handleModeSelect('test')}
        >
          test
        </div>
        <div
          className={cn(styles.AppModeSelect__Item, {
            [styles.AppModeSelect__Item_State_Selected]: isSelected('quest'),
          })}
          onClick={() => handleModeSelect('quest')}
        >
          quest
        </div>
      </div>
    );
  })
);

AppModeSelect.displayName = "AppModeSelect";

export default AppModeSelect;
