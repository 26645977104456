export type IQuiz = IQuestion[];

export interface IAnswer {
  text: string;
  is_correct: boolean;
  id: number;
}

export interface IQuestion {
  question: string;
  explanation: string;
  answers: IAnswer[];
}
export interface IQuizResponse {
  quiz: IQuiz;
  topic: string;
  backgroundUrl: string;
  subjects: string;
  subjects_en: string;
  topic_en: string;
  quiz_introduction: string;
}

export interface IOwnUser {
  userId: string;
  userName: string;
  photoURL: string | undefined;
  invitedFriends: number;
  totalScore: number;
  completedQuizzes: IQuizListItem[];
  createdQuizzes: IQuizListItem[];
  referredUsers: string[];
  difficulty: 0 | 0.5 | 1 | null; // float between 0 and 1, at the moment ui has three options
  lang: string | null;
}

export interface IQuizListItem {
  id: string;
  topic: string;
  passCounter: number;
  creatorId: string;
  theme: string;
  backgroundUrl: string;
  listBackgroundUrl: string;
}

export interface IQuizTheme {
  theme: string;
  quizzes: IQuizListItem[];
}

export interface IQuizUserResult {
  answerPoints: number[];
  answerTimes: number[]; // seconds per question
  answers: Array<number | null>; // answer indexes
  photoURL: string;
  score: number;
  userName: string;
  userId: string;
}

export interface IUserTotalScores {
  photoURL: string;
  totalScore: number;
  userName: string;
  userId: string;
}

export enum QuizMode {
  competition = "1",
  knowledge = "2",
}

export const QuizModeColors: { [key in QuizMode]: string } = {
  [QuizMode.competition]: "#FFF730",
  [QuizMode.knowledge]: "#FEDE67",
};

export type Scene = {
  id: string;
  is_final: boolean;
  is_win: boolean;
  content: string;
  images: string[];
  goal: null | string;
  progress?: number;
  choices?: Array<ISceneChoice>;
};

export interface ISceneChoice {
  id: string;
  index: string | null;
  title: string;
}

export type Movie = {
  id: number;
  title: string;
  start_scene_id: number;
  multilingual?: boolean;
};

export type ApiVersion = 'v4' | 'v5';
export type Language = 'en' | 'ua' | 'ru';